var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-content', [_c('v-switch', {
    staticClass: "v-input--reverse",
    attrs: {
      "dense": "",
      "label": "Current Address",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    model: {
      value: _vm.addressHistory.current,
      callback: function callback($$v) {
        _vm.$set(_vm.addressHistory, "current", $$v);
      },
      expression: "addressHistory.current"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "no-filter": "",
      "dense": "",
      "items": _vm.addressOptions,
      "search-input": _vm.addressHistory.address,
      "item-text": "address",
      "label": "Address",
      "autocomplete": "nofill",
      "hide-no-data": "",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        return _vm.$set(_vm.addressHistory, "address", $event);
      },
      "update:search-input": function updateSearchInput($event) {
        return _vm.$set(_vm.addressHistory, "address", $event);
      }
    },
    model: {
      value: _vm.addressHistoryAddress,
      callback: function callback($$v) {
        _vm.addressHistoryAddress = $$v;
      },
      expression: "addressHistoryAddress"
    }
  }), _c('v-menu', {
    ref: "moveInMenu-".concat(_vm.addressHistoryIdx),
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '####-##-##',
            expression: "'####-##-##'"
          }],
          attrs: {
            "rules": _vm.dateInRules,
            "label": "Move in Date",
            "prepend-icon": "mdi-calendar",
            "hint": "YYYY-MM-DD",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          on: {
            "blur": _vm.dateInUpdate
          },
          model: {
            value: _vm.dateDataIn,
            callback: function callback($$v) {
              _vm.dateDataIn = $$v;
            },
            expression: "dateDataIn"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.moveInMenu,
      callback: function callback($$v) {
        _vm.moveInMenu = $$v;
      },
      expression: "moveInMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "change": function change($event) {
        var _vm2;

        return (_vm2 = _vm).saveDate.apply(_vm2, ["moveInMenu-".concat(_vm.addressHistoryIdx)].concat(Array.prototype.slice.call(arguments)));
      }
    },
    model: {
      value: _vm.addressHistory.move_in,
      callback: function callback($$v) {
        _vm.$set(_vm.addressHistory, "move_in", $$v);
      },
      expression: "addressHistory.move_in"
    }
  })], 1), !_vm.addressHistory.current ? _c('v-menu', {
    ref: "moveOutMenu-".concat(_vm.addressHistoryIdx),
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '####-##-##',
            expression: "'####-##-##'"
          }],
          attrs: {
            "rules": _vm.dateOutRules,
            "label": "Move out Date",
            "prepend-icon": "mdi-calendar",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          on: {
            "blur": _vm.dateOutUpdate
          },
          model: {
            value: _vm.dateDataOut,
            callback: function callback($$v) {
              _vm.dateDataOut = $$v;
            },
            expression: "dateDataOut"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1144178718),
    model: {
      value: _vm.moveOutMenu,
      callback: function callback($$v) {
        _vm.moveOutMenu = $$v;
      },
      expression: "moveOutMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "change": function change($event) {
        var _vm3;

        return (_vm3 = _vm).saveDate.apply(_vm3, ["moveOutMenu-".concat(_vm.addressHistoryIdx)].concat(Array.prototype.slice.call(arguments)));
      }
    },
    model: {
      value: _vm.addressHistory.move_out,
      callback: function callback($$v) {
        _vm.$set(_vm.addressHistory, "move_out", $$v);
      },
      expression: "addressHistory.move_out"
    }
  })], 1) : _vm._e(), _c('v-select', {
    attrs: {
      "dense": "",
      "items": _vm.arrangementOptions,
      "label": "Living Arrangement",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    model: {
      value: _vm.addressHistory.arrangement,
      callback: function callback($$v) {
        _vm.$set(_vm.addressHistory, "arrangement", $$v);
      },
      expression: "addressHistory.arrangement"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>{{ fullName }}</div>
</template>

<script>
export default {
  name: 'AddressHistoryApplicantName',

  props: {
    applicant: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    fullName() {
      const firstName = this.applicant?.data?.personal_details?.first_name;
      const lastName = this.applicant?.data?.personal_details?.last_name;

      const nameExist = Boolean(firstName || lastName);

      if (nameExist) {
        return `${firstName} ${lastName}`;
      }

      return `Please Complete (#${this.applicant?.id})`;
    },
  },
};
</script>

<template>
  <div>
    <v-list-item dense>
      <v-list-item-content>
        <v-switch
          class="v-input--reverse"
          dense
          v-model="addressHistory.current"
          label="Current Address"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />

        <v-autocomplete
          no-filter
          dense
          v-model="addressHistoryAddress"
          :items="addressOptions"
          :search-input.sync="addressHistory.address"
          item-text="address"
          label="Address"
          autocomplete="nofill"
          hide-no-data
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />

        <v-menu
          :ref="`moveInMenu-${addressHistoryIdx}`"
          v-model="moveInMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-mask="'####-##-##'"
              v-model="dateDataIn"
              :rules="dateInRules"
              label="Move in Date"
              prepend-icon="mdi-calendar"
              hint="YYYY-MM-DD"
              v-bind="attrs"
              v-on="on"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
              @blur="dateInUpdate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="addressHistory.move_in"
            @change="saveDate(`moveInMenu-${addressHistoryIdx}`, ...arguments)"
          />
        </v-menu>

        <v-menu
          v-if="!addressHistory.current"
          :ref="`moveOutMenu-${addressHistoryIdx}`"
          v-model="moveOutMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-mask="'####-##-##'"
              v-model="dateDataOut"
              :rules="dateOutRules"
              label="Move out Date"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
              @blur="dateOutUpdate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="addressHistory.move_out"
            @change="saveDate(`moveOutMenu-${addressHistoryIdx}`, ...arguments)"
          />
        </v-menu>

        <v-select
          dense
          v-model="addressHistory.arrangement"
          :items="arrangementOptions"
          label="Living Arrangement"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getAddressList } from '@/services/addresses';
import { clone } from '@/utils/object';

import { dateRequired, dateValidation } from '@/utils/validation';

export default {
  name: 'AddressHistoryComponent',

  emits: ['onUpdateAddressHistory'],

  props: {
    model: {
      type: Object,
      required: true,
    },

    addressHistoryIdx: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dateDataIn: null,

      dateDataOut: null,

      addressOptions: [],

      addressHistoryAddress: null,

      moveInMenu: null,

      moveOutMenu: null,

      addressHistory: {},

      arrangementOptions: [
        'Own Home',
        'Own Home - Mortgage',
        'Boarding', 'With Parents',
        'With Children',
        'Renting',
      ],

      dateInRules: [
        dateRequired('Move in Date'),
        dateValidation('Move in Date'),
      ],

      dateOutRules: [
        dateRequired('Move out Date'),
        dateValidation('Move out Date'),
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED', 'IS_CLIENT']),
  },

  watch: {
    'addressHistory.move_in': {
      handler(val) {
        this.dateDataIn = val;
      },
    },

    'addressHistory.move_out': {
      handler(val) {
        this.dateDataOut = val;
      },
    },

    addressHistory: {
      handler() {
        const payload = {
          id: this.addressHistoryIdx,
          data: this.addressHistory,
        };

        this.$emit('onUpdateAddressHistory', payload);
      },

      deep: true,
    },

    'addressHistory.address': {
      async handler(searchString) {
        this.addressOptions = await getAddressList(searchString);
      },
    },
  },

  mounted() {
    this.addressHistory = clone(this.model);
    this.addressHistoryAddress = this.addressHistory.address;
  },

  methods: {
    async dateInUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.addressHistory.move_in = this.$options.filters.dateISOFormatter(value);
      }
    },

    async dateOutUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.addressHistory.move_out = this.$options.filters.dateISOFormatter(value);
      }
    },

    saveDate(ref, date) {
      this.$refs[ref]?.save(date);
    },
  },
};
</script>

import { clone, isEqual } from '@/utils/object';
import not from '@/utils/common';
import { checkIsSectionVisible } from '@/services/section';

export default {
  data() {
    return {
      sectionPayloadWatcher: null,
    };
  },

  computed: {
    isDataChanged() {
      return this.isDataChangedHandler();
    },

    isSubApplication() {
      const subApplicationRoute = 'SubApplicationAdviser';
      const isSubApplication = Boolean(this.$route.name === subApplicationRoute);

      return isSubApplication;
    },
  },

  watch: {
    isDataChanged: {
      handler(oldValue, newValue) {
        const isDataChangedFlagChanged = Boolean(oldValue !== newValue);
        const canUpdateEditData = Boolean(this.isDataChanged || isDataChangedFlagChanged);

        const isParent = Boolean(this.$props.parentId);
        const id = isParent ? this.$props.parentId : '';

        if (canUpdateEditData) {
          const payload = {
            id,
            sectionKey: this.section.key,
            payload: this[this.section.payloadKey],
            subApplicant: this.section.subApplicant || false,
          };

          this.$store.dispatch('storeEditSectionData', payload);
        }
      },

      immediate: false,
      deep: true,
    },

    existedData: {
      handler(newValue, oldValue) {
        const isDataChanged = not(isEqual(oldValue, newValue));

        if (isDataChanged) {
          this[this.getInitDataVariableName()] = clone(newValue);
          this[this.section.payloadKey] = clone(newValue);
        }
      },

      immediate: false,
      deep: true,
    },
  },

  mounted() {
    this.watchForSectionPayload();
  },

  beforeDestroy() {
    this.unwatchForSectionPayload();
  },

  methods: {
    watchForSectionPayload() {
      const isPayloadKeyExist = Boolean(this.section.payloadKey);

      if (isPayloadKeyExist) {
        this.sectionPayloadWatcher = this.$watch(this.section.payloadKey, this.isDataChangedHandler,
          {
            deep: true,
          });
      }
    },

    unwatchForSectionPayload() {
      const isSectionPayloadWatcherExist = Boolean(typeof this.sectionPayloadWatcher === 'function');

      if (isSectionPayloadWatcherExist) {
        this.sectionPayloadWatcher();
      }
    },

    isDataChangedHandler() {
      const initData = this[this.getInitDataVariableName()];
      const currentData = this[this.section.payloadKey];

      const isDataChanged = this.checkIsDataChanged(initData, currentData);

      return isDataChanged;
    },

    async checkIsSectionVisible() {
      this.isSectionVisible = await checkIsSectionVisible(this.section);
    },

    checkIsDataChanged(initialData, currentData) {
      const isDataNotChanged = isEqual(initialData, currentData);
      const isDataChanged = not(isDataNotChanged);

      return isDataChanged;
    },

    getInitDataVariableName() {
      const initDataVariableName = `initial${this.$options.filters.capitalize(this.section.payloadKey)}`;

      return initDataVariableName;
    },

    async save(payload = {}) {
      const isPayloadEmpty = Boolean(Object.keys(payload)?.length > 0);

      if (isPayloadEmpty) {
        await this.$store.dispatch('storeCommonData', payload);
      } else {
        const isParent = Boolean(this.$props.parentId);

        const id = isParent ? this.$props.parentId : '';

        const sectionPayload = {
          id,
          sectionKey: this.section.key,
          payload: this[this.section.payloadKey],
          subApplicant: this.section.subApplicant || false,
        };

        await this.$store.dispatch('storeSectionData', sectionPayload);
      }

      const clonedPayload = clone(this[this.section.payloadKey]);

      const initDataVariableName = this.getInitDataVariableName();

      this[initDataVariableName] = clonedPayload;
    },
  },
};

<template>
  <div v-if="isVisible">
    <v-expansion-panel-header
      :class="['clickable', isModified ? 'blue' : 'blue-grey']"
      class="lighten-5 section__title"
    >
      <v-icon class="mx-3" v-if="section?.icon">{{ section?.icon }}</v-icon>
      {{ section?.title }}
      <v-spacer></v-spacer>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div :key="isExistedDataChanged">
        <slot name="section" />
      </div>
      <v-fade-transition>
        <v-card-actions>
          <v-btn
            v-if="canShowSaveButton"
            block
            color="warning"
            @click.prevent="save"
            :loading="isSaving"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-fade-transition>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import not from '@/utils/common';

import { hasAccess } from '../../services/section';

export default {
  name: 'BaseSection',

  props: {
    clientCanEdit: {
      type: Boolean,
      default: false,
    },

    isVisible: {
      type: Boolean,
      default: true,
    },

    isModified: {
      type: Boolean,
      default: false,
    },

    isExistedDataChanged: {
      type: Boolean,
      default: false,
    },

    section: {
      type: Object,
      required: true,
      validator(section) {
        const hasTitleProperty = Boolean(Object.prototype.hasOwnProperty.call(section, 'title'));

        return hasTitleProperty;
      },
    },
  },

  emits: ['onSectionSaveClick'],

  data() {
    return {
      isSaving: false,
      isHideSection: false,
      isSectionDisabled: true,
    };
  },

  computed: {
    ...mapGetters(['CURRENT_EDIT_ROLE', 'EDITED_APPLICATION', 'FIELDS_DISABLED', 'IS_CLIENT']),

    canShowSaveButton() {
      const isSectionNotDisabled = not(this.isSectionDisabled);

      const canShowSaveButton = Boolean(this.isModified
      && (isSectionNotDisabled || this.clientCanEdit));

      return canShowSaveButton;
    },
  },

  async created() {
    this.isSectionDisabled = await this.checkIsSectionDisabled();
    this.$store.commit('setFieldsDisabled', this.isSectionDisabled);
  },

  methods: {
    save() {
      this.$emit('onSectionSaveClick');
    },

    async checkIsSectionDisabled() {
      const userHaveAccess = await hasAccess(this.section.access);

      if (userHaveAccess) {
        const userCanEdit = await hasAccess([this.CURRENT_EDIT_ROLE]);

        return not(userCanEdit);
      }

      return true;
    },
  },
};
</script>

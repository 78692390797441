<template>
  <BaseSubSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    :clientCanEdit="IS_CLIENT"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-progress-linear
            class="mb-3"
            :value="addressHistoryProgress"
            height="25"
            dark
            rounded
          >
            <template #default>
            <strong>{{ parseInt(addressHistoryMonth, 10) -  coveredMonths}} months remain</strong>
            </template>
          </v-progress-linear>

          <v-data-iterator
            :items="addressHistory"
            :key="addressHistory.length"
            hide-default-footer
            no-data-text=""
          >
            <template #default>
              <v-row>
                <v-col
                  v-for="(addressHistoryItem, addressHistoryIdx) in addressHistory"
                  :key="addressHistoryIdx"
                  cols="12"
                  lg="6"
                >
                  <v-card>
                    <v-card-subtitle>
                      #{{ addressHistoryIdx + 1 }}
                    </v-card-subtitle>
                    <v-card-text>
                      <v-list>
                        <AddressHistoryComponent
                          :model="addressHistoryItem"
                          :address-history-idx="addressHistoryIdx"
                          @onUpdateAddressHistory="updateAddressHistory"
                        />
                      </v-list>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="red lighten-2"
                        icon
                        @click.stop="showRemoveDialog(addressHistoryIdx)"
                        :disabled="FIELDS_DISABLED && !IS_CLIENT"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>

              <v-dialog
                v-model="isShowRemoveDialog"
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Delete confirmation
                  </v-card-title>

                  <v-card-text>
                    Are you sure?
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="gray darken-1"
                      text
                      @click="closeRemoveDialog"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="red darken-1"
                      text
                      @click="removeAddressHistory"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-iterator>

          <v-card-text class="mt-4">
            <div class="mb-5 button-list">
              <v-btn color="primary"
                @click="copyDialogToggle"
                :disabled="FIELDS_DISABLED && !IS_CLIENT"
              >
                Copy from another applicant
              </v-btn>
              <v-btn color="primary"
                @click="addAddressHistory"
                :disabled="FIELDS_DISABLED && !IS_CLIENT"
              >
                Add new
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-card-text>

      <v-dialog
        v-model="copyDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Select the applicant to copy
          </v-card-title>

          <v-divider></v-divider>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Applicants
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="applicantsExist">
                  <tr
                    v-for="(applicant, index) in applications"
                    :key="index">
                    <td
                      :id='`applicant-${index}`'
                      class="table-row"
                      @click="getApplicant(applicant, index)"
                    >
                      <AddressHistoryApplicantName :applicant="applicant">
                      </AddressHistoryApplicantName>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td>There are no applcants for copy now!</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="copyDialogToggle"
            >
              Close Modal
            </v-btn>
            <v-btn
              v-if="applicantsExist"
              color="primary"
              text
              @click="copyApplicantHistory"
            >
              Copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </BaseSubSection>
</template>

<script>
import Vue from 'vue';

import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSubSection from '@/components/SubApplications/BaseSubSection.vue';

import AddressHistoryApplicantName from '@/components/SubApplications/Sections/AddressHistoryApplicantName.vue';

import AddressHistoryComponent from '@/components/SubApplications/Sections/Components/AddressHistoryComponent.vue';

export default {
  name: 'AddressHistorySection',

  components: {
    BaseSubSection,
    AddressHistoryApplicantName,
    AddressHistoryComponent,
  },

  mixins: [SectionMixin],

  props: {
    parentId: {
      type: String,
    },

    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      applications: [],

      copyDialog: false,

      isSectionVisible: false,

      initialAddressHistory: [],

      addressHistory: [],

      isShowRemoveDialog: false,

      removeAddressHistoryId: null,

      chosenApplicant: [],

      addressHistoryMonth: 36,
    };
  },

  computed: {
    ...mapGetters(['SUB_APPLICATIONS', 'FIELDS_DISABLED', 'IS_CLIENT']),

    applicantsExist() {
      const exist = Boolean(this.applications && this.applications.length > 0);

      return exist;
    },

    coveredMonths() {
      // todo
      const houres = 24;
      const minutes = 60;
      const seconds = 60;
      const milliseconds = 1000;

      const dateFormatter = houres * minutes * seconds * milliseconds;

      const finalDateEdge = new Date();
      const startDateEdge = new Date();

      const monthsDifference = 36;

      startDateEdge.setMonth(startDateEdge.getMonth() - monthsDifference);

      const coveredMonthsList = [];

      const getCoveredMonthsList = (addressHistory) => {
        const isDateExist = Boolean(!addressHistory.move_in !== '' && !addressHistory.move_out !== '');

        if (isDateExist && !addressHistory.current) {
          const startDate = new Date(addressHistory.move_in);
          const endDate = new Date(addressHistory.move_out);

          const startDateParse = Date.parse(startDate);
          const endDateParse = Date.parse(endDate);

          for (let date = startDateParse; date < endDateParse; date += dateFormatter) {
            const computedDate = new Date(date);

            const isEdgeSubsetFinal = computedDate <= finalDateEdge;
            const isEdgeSubsetStart = computedDate >= startDateEdge;
            const isDateEdgeSubset = Boolean(isEdgeSubsetFinal && isEdgeSubsetStart);

            if (isDateEdgeSubset) {
              const year = computedDate.getFullYear();
              const month = computedDate.getMonth();

              const dateToString = `${year}.${month}`;

              const isMonthsCovered = Boolean(coveredMonthsList.indexOf(dateToString) === -1);

              if (isMonthsCovered) {
                coveredMonthsList.push(dateToString);
              }
            }
          }
        } else if (!addressHistory.move_in !== '' && addressHistory.current) {
          const startDate = new Date(addressHistory.move_in);
          const startDateParse = Date.parse(startDate);

          const endDateParse = Date.parse(finalDateEdge);

          for (let date = startDateParse; date < endDateParse; date += dateFormatter) {
            const computedDate = new Date(date);

            const isEdgeSubsetStart = Boolean(computedDate >= startDateEdge);

            if (isEdgeSubsetStart) {
              const year = computedDate.getFullYear();
              const month = computedDate.getMonth();

              const dateToString = `${year}.${month}`;

              const isMonthsCovered = Boolean(coveredMonthsList.indexOf(dateToString) === -1);

              if (isMonthsCovered) {
                coveredMonthsList.push(dateToString);
              }
            }
          }
        }
      };
      this.addressHistory.forEach(getCoveredMonthsList);

      const coverMonthsLength = coveredMonthsList.length;

      const isFullCovered = Boolean(coverMonthsLength > this.addressHistoryMonth);

      return isFullCovered ? this.addressHistoryMonth : coverMonthsLength;
    },

    addressHistoryProgress() {
      return Math.round(100 * (this.coveredMonths / parseInt(this.addressHistoryMonth, 10)));
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialAddressHistory = clone(this.existedData);
    this.addressHistory = clone(this.existedData);

    this.setApplications();
  },

  methods: {
    setApplications() {
      this.applications = this.SUB_APPLICATIONS.filter((item) => item.id !== this.parentId);
    },

    getApplicant(applicant, index) {
      const prevActiveRow = document.querySelector('.table-row.active');
      const chosenRow = document.getElementById(`applicant-${index}`);
      if (prevActiveRow) {
        prevActiveRow.classList.remove('active');
      }
      chosenRow.classList.add('active');

      this.chosenApplicant = applicant.data.address_history;
    },

    copyDialogToggle() {
      this.copyDialog = !this.copyDialog;
    },

    copyApplicantHistory() {
      this.chosenApplicant.forEach((item) => {
        const addressHistoryData = {
          current: item.current,
          address: item.address,
          move_in: item.move_in,
          move_out: item.move_out,
          arrangement: item.arrangement,
          cost: item.cost,
        };

        this.addressHistory.push(addressHistoryData);
      });

      this.copyDialogToggle();
    },

    updateAddressHistory(addressHistory) {
      Vue.set(this.addressHistory, addressHistory.id, addressHistory.data);
    },

    addAddressHistory() {
      const addressHistoryData = {
        address: '',
        move_in: '',
        move_out: '',
        arrangement: '',
        cost: '',
      };

      this.addressHistory.push(addressHistoryData);
    },

    removeAddressHistory() {
      this.addressHistory.splice(this.removeAddressHistoryId, 1);

      this.closeRemoveDialog();
    },

    showRemoveDialog(idxAddressHistory) {
      this.removeAddressHistoryId = idxAddressHistory;
      this.isShowRemoveDialog = true;
    },

    closeRemoveDialog() {
      this.removeAddressHistoryId = null;
      this.isShowRemoveDialog = false;
    },
  },

  watch: {
    SUB_APPLICATIONS: {
      handler() {
        this.setApplications();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .button-list {
    .v-btn:not(:last-child) {
      margin-right: 20px;
    }
  }
  .table-row {
    cursor: pointer;
    &.active {
      background: #eee;
    }
  }
</style>
